import { createApp } from 'vue'
import App from './App.vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/css/bootstrap.rtl.css'
// import 'bootstrap'
//import '@/assets/styles/main.scss'
import './registerServiceWorker'
//import './cacheWorker.js'

import router from './router'
import { i18n } from './i18n'; // Adjust the path according to where you created i18n.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import VueLazyload from 'vue-lazyload';
 
//import VueGtm from 'vue-gtm';
//require('dotenv').config();
//import 'bootstrap';
//import  "@popperjs/core"
// Import just what we need

 import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
  
if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                const src = img.getAttribute('data-src');
                img.setAttribute('src', src);
                observer.unobserve(img);
            }
        });
    });

    document.querySelectorAll('img.lazy').forEach(img => {
        observer.observe(img);
    });
}

// document.addEventListener('DOMContentLoaded', () => {
//     const imagesToPreload = [
//         '@/assets/fonts/Inter/Inter-ExtraLight.woff2',
//         '@/assets/fonts/Inter/Inter-Thin.woff2',
//         '@/assets/fonts/Inter/Inter-Light.woff2',
//         '@/assets/fonts/Inter/Inter-Regular.woff2',
//         '@/assets/fonts/Inter/Inter-Medium.woff2',
//         '@/assets/fonts/Inter/Inter-SemiBold.woff2',
//         '@/assets/fonts/Inter/Inter-Bold.woff2',
//         '@/assets/fonts/Inter/Inter-ExtraBold.woff2',
//         '@/assets/fonts/Inter/Inter-Black.woff2',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-ExtraLight.ttf',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-Light.ttf',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-Regular.ttf',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-Medium.ttf',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-SemiBold.ttf',
//         '@/assets/fonts/IBM/IBMPlexSansArabic-Bold.ttf' 
//     ];
//     imagesToPreload.forEach((imageSrc) => {
//         const link = document.createElement('link');
//         link.rel = 'preload';
//         link.as = 'font';
//         link.href = imageSrc;
//         document.head.appendChild(link);
//     });
// });

//App.component('BButton', BButton)
//   .use(VueGtm, {
//       id: 'GTM-NXDF9T9', // Your GTM container ID here
//       defer: false, // true in case you want to load GTM in the head, false if in the body
//       compatibility: false, // true if you want to load GTM for older browsers
//       debug: true, // Whether or not display console logs debugs (optional)
//       loadScript: true, // true if you want to load the GTM script asynchronously (optional)
//       vueRouter: app._router, // Pass the router instance to automatically sync with router (optional)
//   })
const app = createApp(App);
const path = window.location.pathname;
if (path.includes('/en') || path.includes('/ar')) {
    const initialLang = path.includes('/en') ? 'en' : 'ar';
    sessionStorage.setItem('lang', initialLang);  // Set for the session duration
}
app.use(VueLazyload).use(i18n).use(router)
 
   .mount('#app')

<template>
    <div class="container text-start">
        <footer class="py-5">
            <div class="row flex-order-sm ">
                <div class="col-12 col-md-2 mb-3 order-sm-1 text-md-center text-sm-start">
                    <router-link class="navbar-brand" aria-label="Home" :to="`/`">
                        <svg width="91" class="logo " height="40" viewBox="0 0 91 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M49.3051 20.1258V24.0521H52.8866V22.089H57.3636V24.0521L53.782 27.9806H44.8281V10.2967L48.4097 6.36816H57.3636V16.1883L53.782 20.1169L49.3051 20.1258ZM52.8866 10.3056H49.3051V16.1973H52.8866V10.3056Z"
                                fill="#0000E2" />
                            <path
                                d="M64.5444 17.1803L60.9629 13.2518V6.37744H65.4398V12.2691L67.2306 14.2322L69.0214 12.2691V6.37744H73.4983V13.2518L69.9168 17.1803L73.4983 21.1066V27.9809H69.0214V22.0893L67.2306 20.1261L65.4398 22.0893V27.9809H60.9629V21.1066L64.5444 17.1803Z"
                                fill="#0000E2" />
                            <path
                                d="M86.077 6.37744H80.6734L77.0918 10.306V12.2691H81.5687V10.306H85.1503V14.2322H80.6734L77.0918 18.1608V27.9809H86.0457L89.6273 24.0524V10.3037H86.0681L86.077 6.37744ZM85.1548 24.0614H81.5732V18.1608H85.1548V24.0614Z"
                                fill="#0000E2" />
                            <path d="M91.0002 4.90967H87.3672V8.8449H91.0002V4.90967Z" fill="#0000E2" />
                            <path
                                d="M12.2656 10.172V28.3037H21.6448L25.4032 24.1849V6.05322H16.024L12.2656 10.172ZM20.3241 10.6197V23.7215H17.3358V10.6287L20.3241 10.6197ZM24.2549 6.69119L20.5144 9.97279H16.747V24.3774H20.9218V10.4608L24.8123 7.04711V23.9207L21.4008 27.659H12.8543V10.4384L16.268 6.70014L24.2549 6.69119Z"
                                fill="#0000E2" />
                            <path
                                d="M21.7231 28.4731H12.0977V10.0952L15.9366 5.87793H25.5621V24.2558L21.7231 28.4731ZM12.4401 28.1216H21.5686L25.2263 24.1125V6.22937H16.1L12.4401 10.2385V28.1216ZM21.4791 27.8262H12.6797V10.3661L16.1896 6.53604H24.7316L20.5904 10.1691H16.9216V24.211H20.7471V10.3795L24.989 6.65915V23.9961L21.4791 27.8262ZM13.0266 27.4837H21.309L24.6242 23.8506V7.43143L21.0829 10.5362V24.549H16.5724V9.80645H20.4584L23.7982 6.87405H16.344L13.0266 10.5071V27.4837ZM20.5031 23.9021H17.1611V10.4534H20.5076L20.5031 23.9021ZM17.5036 23.5484H20.1472V10.7891H17.5036V23.5484Z"
                                fill="#0000E2" />
                            <path
                                d="M32.1568 6.05566L28.3984 10.1722V28.3039H36.4569V31.5855H31.98V36.1587H37.7754L41.536 32.0555V6.05566H32.1568ZM32.5732 35.5162V32.2324H37.0501V27.6592H28.9916V10.4386L32.4053 6.70035H40.4034L36.6629 9.98195H32.8799V24.3776H37.0546V10.4632L40.9451 7.04955V31.7757L37.5336 35.5162H32.5732ZM36.4637 10.6289V23.7307H33.4686V10.6289H36.4637Z"
                                fill="#0000E2" />
                            <path
                                d="M37.854 36.3377H31.8101V31.4131H36.287V28.4739H28.2285V10.0961L32.0832 5.87207H41.7086V32.1115L37.854 36.3377ZM32.1593 35.9863H37.6995L41.3594 31.9772V6.2347H32.2331L28.5732 10.2438V28.1337H36.6317V31.7667H32.1548L32.1593 35.9863ZM37.61 35.6908H32.3966V32.0555H36.8735V27.8337H28.815V10.3736L32.3227 6.5369H40.8669L36.7235 10.1699H33.0547V24.2119H36.878V10.3826L41.1311 6.66225V31.8451L37.61 35.6908ZM32.748 35.3393H37.4488L40.764 31.7085V7.43229L37.2227 10.537V24.5477H32.7032V9.8073H36.5915L39.9335 6.87491H32.4771L29.1642 10.5079V27.4845H37.2227V32.4092H32.7458L32.748 35.3393ZM36.6407 23.9075H33.2942V10.4542H36.6407V23.9075ZM33.6456 23.5493H36.2893V10.79H33.6456V23.5493Z"
                                fill="#0000E2" />
                            <path
                                d="M1.08083 3.05524L1.01367 28.3164H9.2714V0.174316H4.29751L1.08083 3.05524ZM4.78998 3.81855V0.821239H8.14769L4.78998 3.81855ZM8.68268 1.18163V27.6717H4.78998V4.65126L8.68268 1.18163ZM1.61134 27.6717L1.67626 3.36414L4.20798 1.12567V27.674L1.61134 27.6717Z"
                                fill="#0000E2" />
                            <path
                                d="M9.44511 28.4935H0.835938L0.905333 2.97493L4.23842 0H9.44511V28.4935ZM1.18962 28.1421H9.09591V0.349201H4.37273L1.25453 3.13386L1.18962 28.1421ZM8.85639 27.8466H4.60329V4.56649L8.85639 0.783466V27.8466ZM4.96816 27.4952H8.50495V1.57365L4.96816 4.7299V27.4952ZM4.37721 27.8466H1.42914L1.49405 3.28384L4.37497 0.734219L4.37721 27.8466ZM1.78281 27.4952H4.02129V1.51321L1.8343 3.44277L1.78281 27.4952ZM4.61672 4.21505V0.644682H8.61463L4.61672 4.21505ZM4.96816 0.996124V3.4271L7.69462 0.996124H4.96816Z"
                                fill="#0000E2" />
                            <path d="M89.6958 31.3237H44.8301V40.0001H89.6958V31.3237Z" fill="#FFEC00" />
                            <path d="M47.1777 37.4526V34.126H47.729V36.9489H49.2355V37.4526H47.1777Z" fill="black" />
                            <path
                                d="M50.691 37.5096C50.4534 37.5096 50.2411 37.4573 50.0542 37.3528C49.8704 37.2451 49.7263 37.0978 49.6217 36.9108C49.5172 36.7239 49.4649 36.5085 49.4649 36.2645C49.4649 36.0206 49.5156 35.8051 49.617 35.6182C49.7215 35.4313 49.8657 35.2856 50.0494 35.181C50.2332 35.0733 50.4439 35.0194 50.6815 35.0194C50.9191 35.0194 51.1298 35.0733 51.3135 35.181C51.4973 35.2856 51.6398 35.4313 51.7412 35.6182C51.8458 35.8051 51.898 36.0206 51.898 36.2645C51.898 36.5085 51.8474 36.7239 51.746 36.9108C51.6446 37.0978 51.502 37.2451 51.3183 37.3528C51.1345 37.4573 50.9254 37.5096 50.691 37.5096ZM50.691 37.0201C50.8209 37.0201 50.9365 36.9885 51.0379 36.9251C51.1393 36.8586 51.2185 36.7699 51.2755 36.659C51.3325 36.5449 51.361 36.4134 51.361 36.2645C51.361 36.1156 51.3309 35.9857 51.2707 35.8748C51.2137 35.7608 51.1345 35.6721 51.0331 35.6087C50.9318 35.5422 50.8145 35.5089 50.6815 35.5089C50.5484 35.5089 50.4312 35.5406 50.3298 35.604C50.2284 35.6673 50.1492 35.756 50.0922 35.8701C50.0352 35.9841 50.0066 36.1156 50.0066 36.2645C50.0066 36.4134 50.0352 36.5449 50.0922 36.659C50.1524 36.7699 50.2332 36.8586 50.3345 36.9251C50.4391 36.9885 50.5579 37.0201 50.691 37.0201Z"
                                fill="black" />
                            <path
                                d="M53.381 38.4648C53.2131 38.4648 53.061 38.4506 52.9248 38.4221C52.7917 38.3935 52.6793 38.3603 52.5874 38.3223C52.4955 38.2842 52.4242 38.2478 52.3735 38.213L52.5779 37.7853C52.6222 37.8074 52.6809 37.8344 52.7537 37.866C52.8298 37.9009 52.9185 37.931 53.0198 37.9563C53.1212 37.9817 53.2353 37.9944 53.362 37.9944C53.4982 37.9944 53.6202 37.9658 53.7279 37.9088C53.8357 37.855 53.9196 37.771 53.9798 37.6569C54.04 37.5429 54.0701 37.3972 54.0701 37.2197V35.0765H54.6024V37.2055C54.6024 37.4748 54.5517 37.7029 54.4503 37.8898C54.3489 38.0799 54.2063 38.2225 54.0226 38.3175C53.8388 38.4157 53.625 38.4648 53.381 38.4648ZM53.3002 37.4051C53.0848 37.4051 52.8963 37.356 52.7347 37.2578C52.5763 37.1595 52.4512 37.0233 52.3593 36.8491C52.2706 36.6716 52.2262 36.4673 52.2262 36.236C52.2262 35.9889 52.2706 35.775 52.3593 35.5945C52.4512 35.4139 52.5763 35.2729 52.7347 35.1715C52.8963 35.0701 53.0848 35.0194 53.3002 35.0194C53.4935 35.0194 53.6614 35.0701 53.804 35.1715C53.9497 35.2729 54.0622 35.4155 54.1414 35.5992C54.2206 35.7798 54.2602 35.9936 54.2602 36.2408C54.2602 36.4752 54.2206 36.6796 54.1414 36.8538C54.0622 37.0281 53.9497 37.1643 53.804 37.2625C53.6614 37.3576 53.4935 37.4051 53.3002 37.4051ZM53.4476 36.9536C53.5679 36.9536 53.6725 36.9219 53.7612 36.8586C53.8531 36.7952 53.9244 36.7097 53.9751 36.6019C54.0257 36.4911 54.0511 36.3643 54.0511 36.2218C54.0511 36.076 54.0257 35.9493 53.9751 35.8416C53.9244 35.7307 53.8531 35.6451 53.7612 35.585C53.6693 35.5216 53.5632 35.4899 53.4428 35.4899C53.3161 35.4899 53.2036 35.5216 53.1054 35.585C53.0103 35.6451 52.9343 35.7307 52.8773 35.8416C52.8234 35.9493 52.7965 36.076 52.7965 36.2218C52.7965 36.3643 52.8234 36.4911 52.8773 36.6019C52.9343 36.7097 53.0119 36.7952 53.1101 36.8586C53.2084 36.9219 53.3208 36.9536 53.4476 36.9536Z"
                                fill="black" />
                            <path
                                d="M55.2235 37.4526V35.0765H55.751V37.4526H55.2235ZM55.4896 34.625C55.4041 34.625 55.3296 34.5949 55.2663 34.5347C55.2061 34.4713 55.176 34.3969 55.176 34.3113C55.176 34.2258 55.2061 34.1529 55.2663 34.0927C55.3296 34.0325 55.4041 34.0024 55.4896 34.0024C55.5752 34.0024 55.648 34.0325 55.7082 34.0927C55.7716 34.1529 55.8033 34.2258 55.8033 34.3113C55.8033 34.3969 55.7716 34.4713 55.7082 34.5347C55.648 34.5949 55.5752 34.625 55.4896 34.625Z"
                                fill="black" />
                            <path
                                d="M57.2432 37.5096C57.0848 37.5096 56.9391 37.4859 56.806 37.4383C56.6729 37.3908 56.5589 37.3243 56.4638 37.2387C56.3688 37.15 56.2959 37.0471 56.2452 36.9298L56.7062 36.7255C56.7537 36.8142 56.8234 36.8887 56.9153 36.9489C57.0104 37.0091 57.1149 37.0391 57.229 37.0391C57.3525 37.0391 57.4507 37.0186 57.5236 36.9774C57.5996 36.933 57.6377 36.8728 57.6377 36.7968C57.6377 36.7239 57.6091 36.6685 57.5521 36.6305C57.4951 36.5893 57.4143 36.556 57.3097 36.5307L57.0864 36.4689C56.8614 36.4118 56.6856 36.3216 56.5589 36.198C56.4322 36.0713 56.3688 35.9271 56.3688 35.7655C56.3688 35.5279 56.4448 35.3442 56.5969 35.2143C56.7521 35.0844 56.9739 35.0194 57.2622 35.0194C57.408 35.0194 57.541 35.0416 57.6614 35.086C57.785 35.1271 57.8911 35.1873 57.9798 35.2665C58.0685 35.3426 58.1303 35.4329 58.1652 35.5374L57.7232 35.737C57.6915 35.6578 57.6313 35.5976 57.5426 35.5564C57.4539 35.5121 57.3557 35.4899 57.248 35.4899C57.1402 35.4899 57.0563 35.5137 56.9961 35.5612C56.9359 35.6055 56.9058 35.6689 56.9058 35.7513C56.9058 35.7988 56.9327 35.8432 56.9866 35.8843C57.0404 35.9224 57.1181 35.954 57.2195 35.9794L57.4998 36.0459C57.6551 36.0839 57.7818 36.1441 57.88 36.2265C57.9782 36.3057 58.0511 36.396 58.0986 36.4974C58.1461 36.5956 58.1699 36.6954 58.1699 36.7968C58.1699 36.9394 58.1287 37.0645 58.0463 37.1722C57.9671 37.2799 57.8578 37.3639 57.7184 37.4241C57.579 37.4811 57.4206 37.5096 57.2432 37.5096Z"
                                fill="black" />
                            <path
                                d="M59.5377 37.5096C59.2874 37.5096 59.0957 37.4478 58.9627 37.3243C58.8328 37.1976 58.7678 37.017 58.7678 36.7825V34.3351H59.2953V36.6827C59.2953 36.7936 59.3207 36.8776 59.3714 36.9346C59.4252 36.9916 59.5029 37.0201 59.6042 37.0201C59.6359 37.0201 59.6708 37.0138 59.7088 37.0011C59.7468 36.9885 59.7896 36.9647 59.8371 36.9298L60.0319 37.3338C59.9496 37.3908 59.8672 37.4336 59.7848 37.4621C59.7025 37.4938 59.6201 37.5096 59.5377 37.5096ZM58.3734 35.5327V35.0765H59.9369V35.5327H58.3734Z"
                                fill="black" />
                            <path
                                d="M60.4909 37.4526V35.0765H61.0184V37.4526H60.4909ZM60.757 34.625C60.6715 34.625 60.597 34.5949 60.5337 34.5347C60.4735 34.4713 60.4434 34.3969 60.4434 34.3113C60.4434 34.2258 60.4735 34.1529 60.5337 34.0927C60.597 34.0325 60.6715 34.0024 60.757 34.0024C60.8426 34.0024 60.9155 34.0325 60.9756 34.0927C61.039 34.1529 61.0707 34.2258 61.0707 34.3113C61.0707 34.3969 61.039 34.4713 60.9756 34.5347C60.9155 34.5949 60.8426 34.625 60.757 34.625Z"
                                fill="black" />
                            <path
                                d="M62.7578 37.5096C62.5138 37.5096 62.2984 37.4573 62.1114 37.3528C61.9245 37.2451 61.7772 37.0978 61.6695 36.9108C61.5649 36.7239 61.5127 36.5085 61.5127 36.2645C61.5127 36.0206 61.5649 35.8051 61.6695 35.6182C61.7772 35.4313 61.9229 35.2856 62.1067 35.181C62.2936 35.0733 62.5075 35.0194 62.7482 35.0194C62.9827 35.0194 63.195 35.0765 63.3851 35.1905C63.5751 35.3014 63.7161 35.4614 63.808 35.6705L63.3138 35.8606C63.2631 35.756 63.1855 35.6721 63.0809 35.6087C62.9764 35.5453 62.8575 35.5137 62.7245 35.5137C62.5946 35.5137 62.479 35.5469 62.3776 35.6135C62.2762 35.6768 62.197 35.7655 62.14 35.8796C62.0829 35.9905 62.0544 36.1188 62.0544 36.2645C62.0544 36.4103 62.0829 36.5402 62.14 36.6542C62.2002 36.7651 62.2809 36.8538 62.3823 36.9203C62.4869 36.9837 62.6041 37.0154 62.734 37.0154C62.8671 37.0154 62.9859 36.9821 63.0904 36.9156C63.195 36.8459 63.2726 36.7524 63.3233 36.6352L63.8175 36.8253C63.7256 37.0407 63.5846 37.2086 63.3946 37.329C63.2076 37.4494 62.9954 37.5096 62.7578 37.5096Z"
                                fill="black" />
                            <path
                                d="M65.0956 37.5096C64.9372 37.5096 64.7915 37.4859 64.6584 37.4383C64.5253 37.3908 64.4113 37.3243 64.3162 37.2387C64.2212 37.15 64.1483 37.0471 64.0976 36.9298L64.5586 36.7255C64.6061 36.8142 64.6758 36.8887 64.7677 36.9489C64.8628 37.0091 64.9673 37.0391 65.0814 37.0391C65.2049 37.0391 65.3031 37.0186 65.376 36.9774C65.452 36.933 65.4901 36.8728 65.4901 36.7968C65.4901 36.7239 65.4615 36.6685 65.4045 36.6305C65.3475 36.5893 65.2667 36.556 65.1621 36.5307L64.9388 36.4689C64.7138 36.4118 64.538 36.3216 64.4113 36.198C64.2846 36.0713 64.2212 35.9271 64.2212 35.7655C64.2212 35.5279 64.2972 35.3442 64.4493 35.2143C64.6045 35.0844 64.8263 35.0194 65.1146 35.0194C65.2604 35.0194 65.3934 35.0416 65.5138 35.086C65.6374 35.1271 65.7435 35.1873 65.8322 35.2665C65.9209 35.3426 65.9827 35.4329 66.0176 35.5374L65.5756 35.737C65.5439 35.6578 65.4837 35.5976 65.395 35.5564C65.3063 35.5121 65.2081 35.4899 65.1004 35.4899C64.9926 35.4899 64.9087 35.5137 64.8485 35.5612C64.7883 35.6055 64.7582 35.6689 64.7582 35.7513C64.7582 35.7988 64.7851 35.8432 64.839 35.8843C64.8928 35.9224 64.9705 35.954 65.0719 35.9794L65.3522 36.0459C65.5075 36.0839 65.6342 36.1441 65.7324 36.2265C65.8306 36.3057 65.9035 36.396 65.951 36.4974C65.9985 36.5956 66.0223 36.6954 66.0223 36.7968C66.0223 36.9394 65.9811 37.0645 65.8987 37.1722C65.8195 37.2799 65.7102 37.3639 65.5708 37.4241C65.4314 37.4811 65.273 37.5096 65.0956 37.5096Z"
                                fill="black" />
                            <path
                                d="M68.7835 37.5096C68.6093 37.5096 68.4493 37.489 68.3036 37.4478C68.161 37.4067 68.0343 37.3496 67.9234 37.2768C67.8157 37.2039 67.7254 37.1231 67.6525 37.0344C67.5828 36.9425 67.5337 36.8475 67.5052 36.7493L68.0469 36.5829C68.0881 36.7002 68.1689 36.8031 68.2893 36.8918C68.4097 36.9805 68.5586 37.0265 68.736 37.0296C68.9419 37.0296 69.1051 36.9869 69.2255 36.9013C69.3459 36.8158 69.4061 36.7033 69.4061 36.5639C69.4061 36.4372 69.3554 36.3342 69.254 36.255C69.1526 36.1726 69.0164 36.1093 68.8453 36.0649L68.4366 35.9604C68.2814 35.9192 68.1404 35.859 68.0137 35.7798C67.8901 35.7006 67.7919 35.6008 67.719 35.4804C67.6493 35.36 67.6145 35.2174 67.6145 35.0527C67.6145 34.7422 67.7159 34.5014 67.9186 34.3303C68.1214 34.1561 68.4113 34.069 68.7883 34.069C69.0006 34.069 69.1859 34.1022 69.3443 34.1688C69.5059 34.2321 69.6389 34.3208 69.7435 34.4349C69.848 34.5458 69.9257 34.6725 69.9764 34.8151L69.4441 34.9862C69.3966 34.8594 69.3142 34.7549 69.197 34.6725C69.0798 34.5901 68.934 34.549 68.7598 34.549C68.5792 34.549 68.4366 34.5917 68.3321 34.6773C68.2307 34.7628 68.18 34.8816 68.18 35.0337C68.18 35.1572 68.2196 35.2539 68.2988 35.3236C68.3812 35.3901 68.4921 35.4408 68.6315 35.4756L69.0402 35.5754C69.338 35.6483 69.5692 35.7735 69.734 35.9509C69.8987 36.1283 69.9811 36.3247 69.9811 36.5402C69.9811 36.7303 69.9352 36.8982 69.8433 37.0439C69.7514 37.1896 69.6152 37.3037 69.4346 37.3861C69.2572 37.4684 69.0402 37.5096 68.7835 37.5096Z"
                                fill="black" />
                            <path
                                d="M71.5843 37.5096C71.3467 37.5096 71.1344 37.4573 70.9475 37.3528C70.7637 37.2451 70.6196 37.0978 70.515 36.9108C70.4105 36.7239 70.3582 36.5085 70.3582 36.2645C70.3582 36.0206 70.4089 35.8051 70.5103 35.6182C70.6148 35.4313 70.759 35.2856 70.9427 35.181C71.1265 35.0733 71.3372 35.0194 71.5748 35.0194C71.8124 35.0194 72.0231 35.0733 72.2068 35.181C72.3906 35.2856 72.5332 35.4313 72.6346 35.6182C72.7391 35.8051 72.7914 36.0206 72.7914 36.2645C72.7914 36.5085 72.7407 36.7239 72.6393 36.9108C72.5379 37.0978 72.3954 37.2451 72.2116 37.3528C72.0278 37.4573 71.8187 37.5096 71.5843 37.5096ZM71.5843 37.0201C71.7142 37.0201 71.8298 36.9885 71.9312 36.9251C72.0326 36.8586 72.1118 36.7699 72.1688 36.659C72.2259 36.5449 72.2544 36.4134 72.2544 36.2645C72.2544 36.1156 72.2243 35.9857 72.1641 35.8748C72.107 35.7608 72.0278 35.6721 71.9265 35.6087C71.8251 35.5422 71.7079 35.5089 71.5748 35.5089C71.4417 35.5089 71.3245 35.5406 71.2231 35.604C71.1217 35.6673 71.0425 35.756 70.9855 35.8701C70.9285 35.9841 70.9 36.1156 70.9 36.2645C70.9 36.4134 70.9285 36.5449 70.9855 36.659C71.0457 36.7699 71.1265 36.8586 71.2279 36.9251C71.3324 36.9885 71.4512 37.0201 71.5843 37.0201Z"
                                fill="black" />
                            <path d="M73.2526 37.4526V34.126H73.7849V37.4526H73.2526Z" fill="black" />
                            <path
                                d="M75.9677 37.4526L75.9392 37.0154V35.0765H76.4667V37.4526H75.9677ZM74.3234 36.293V35.0765H74.8557V36.293H74.3234ZM74.8557 36.293C74.8557 36.48 74.8779 36.6257 74.9222 36.7303C74.9666 36.8348 75.0268 36.9077 75.1028 36.9489C75.182 36.99 75.2723 37.0106 75.3737 37.0106C75.5543 37.0138 75.6937 36.9552 75.7919 36.8348C75.8901 36.7144 75.9392 36.5417 75.9392 36.3168H76.1388C76.1388 36.5671 76.1008 36.7825 76.0247 36.9631C75.9519 37.1405 75.8473 37.2768 75.7111 37.3718C75.578 37.4637 75.4165 37.5096 75.2264 37.5096C75.0394 37.5096 74.8779 37.4716 74.7416 37.3956C74.6086 37.3195 74.5056 37.2007 74.4327 37.0391C74.3599 36.8776 74.3234 36.6701 74.3234 36.4166V36.293H74.8557Z"
                                fill="black" />
                            <path
                                d="M78.0038 37.5096C77.7536 37.5096 77.5619 37.4478 77.4288 37.3243C77.2989 37.1976 77.234 37.017 77.234 36.7825V34.3351H77.7615V36.6827C77.7615 36.7936 77.7868 36.8776 77.8375 36.9346C77.8914 36.9916 77.969 37.0201 78.0704 37.0201C78.1021 37.0201 78.1369 37.0138 78.1749 37.0011C78.2129 36.9885 78.2557 36.9647 78.3032 36.9298L78.4981 37.3338C78.4157 37.3908 78.3333 37.4336 78.251 37.4621C78.1686 37.4938 78.0862 37.5096 78.0038 37.5096ZM76.8395 35.5327V35.0765H78.403V35.5327H76.8395Z"
                                fill="black" />
                            <path
                                d="M78.957 37.4526V35.0765H79.4846V37.4526H78.957ZM79.2232 34.625C79.1376 34.625 79.0632 34.5949 78.9998 34.5347C78.9396 34.4713 78.9095 34.3969 78.9095 34.3113C78.9095 34.2258 78.9396 34.1529 78.9998 34.0927C79.0632 34.0325 79.1376 34.0024 79.2232 34.0024C79.3087 34.0024 79.3816 34.0325 79.4418 34.0927C79.5051 34.1529 79.5368 34.2258 79.5368 34.3113C79.5368 34.3969 79.5051 34.4713 79.4418 34.5347C79.3816 34.5949 79.3087 34.625 79.2232 34.625Z"
                                fill="black" />
                            <path
                                d="M81.2049 37.5096C80.9673 37.5096 80.755 37.4573 80.5681 37.3528C80.3843 37.2451 80.2402 37.0978 80.1356 36.9108C80.0311 36.7239 79.9788 36.5085 79.9788 36.2645C79.9788 36.0206 80.0295 35.8051 80.1309 35.6182C80.2354 35.4313 80.3796 35.2856 80.5633 35.181C80.7471 35.0733 80.9578 35.0194 81.1954 35.0194C81.433 35.0194 81.6437 35.0733 81.8274 35.181C82.0112 35.2856 82.1538 35.4313 82.2551 35.6182C82.3597 35.8051 82.412 36.0206 82.412 36.2645C82.412 36.5085 82.3613 36.7239 82.2599 36.9108C82.1585 37.0978 82.0159 37.2451 81.8322 37.3528C81.6484 37.4573 81.4393 37.5096 81.2049 37.5096ZM81.2049 37.0201C81.3348 37.0201 81.4504 36.9885 81.5518 36.9251C81.6532 36.8586 81.7324 36.7699 81.7894 36.659C81.8464 36.5449 81.875 36.4134 81.875 36.2645C81.875 36.1156 81.8449 35.9857 81.7847 35.8748C81.7276 35.7608 81.6484 35.6721 81.547 35.6087C81.4457 35.5422 81.3284 35.5089 81.1954 35.5089C81.0623 35.5089 80.9451 35.5406 80.8437 35.604C80.7423 35.6673 80.6631 35.756 80.6061 35.8701C80.5491 35.9841 80.5205 36.1156 80.5205 36.2645C80.5205 36.4134 80.5491 36.5449 80.6061 36.659C80.6663 36.7699 80.7471 36.8586 80.8485 36.9251C80.953 36.9885 81.0718 37.0201 81.2049 37.0201Z"
                                fill="black" />
                            <path
                                d="M82.8732 37.4526V35.0765H83.3722L83.4054 35.5137V37.4526H82.8732ZM84.4842 37.4526V36.236H85.0165V37.4526H84.4842ZM84.4842 36.236C84.4842 36.0459 84.462 35.9002 84.4177 35.7988C84.3765 35.6943 84.3163 35.6214 84.2371 35.5802C84.1611 35.539 84.0708 35.5184 83.9662 35.5184C83.7888 35.5153 83.651 35.5739 83.5528 35.6943C83.4545 35.8146 83.4054 35.9873 83.4054 36.2123H83.2058C83.2058 35.962 83.2423 35.7481 83.3152 35.5707C83.388 35.3901 83.4926 35.2539 83.6288 35.162C83.765 35.067 83.9266 35.0194 84.1135 35.0194C84.3036 35.0194 84.4652 35.0574 84.5983 35.1335C84.7345 35.2095 84.8375 35.3283 84.9072 35.4899C84.98 35.6483 85.0165 35.8558 85.0165 36.1125V36.236H84.4842Z"
                                fill="black" />
                            <path
                                d="M86.4205 37.5096C86.2621 37.5096 86.1164 37.4859 85.9833 37.4383C85.8503 37.3908 85.7362 37.3243 85.6412 37.2387C85.5461 37.15 85.4733 37.0471 85.4226 36.9298L85.8835 36.7255C85.9311 36.8142 86.0008 36.8887 86.0926 36.9489C86.1877 37.0091 86.2922 37.0391 86.4063 37.0391C86.5298 37.0391 86.6281 37.0186 86.7009 36.9774C86.777 36.933 86.815 36.8728 86.815 36.7968C86.815 36.7239 86.7865 36.6685 86.7294 36.6305C86.6724 36.5893 86.5916 36.556 86.4871 36.5307L86.2637 36.4689C86.0388 36.4118 85.8629 36.3216 85.7362 36.198C85.6095 36.0713 85.5461 35.9271 85.5461 35.7655C85.5461 35.5279 85.6222 35.3442 85.7742 35.2143C85.9295 35.0844 86.1512 35.0194 86.4395 35.0194C86.5853 35.0194 86.7184 35.0416 86.8387 35.086C86.9623 35.1271 87.0684 35.1873 87.1571 35.2665C87.2459 35.3426 87.3076 35.4329 87.3425 35.5374L86.9005 35.737C86.8688 35.6578 86.8086 35.5976 86.7199 35.5564C86.6312 35.5121 86.533 35.4899 86.4253 35.4899C86.3176 35.4899 86.2336 35.5137 86.1734 35.5612C86.1132 35.6055 86.0831 35.6689 86.0831 35.7513C86.0831 35.7988 86.1101 35.8432 86.1639 35.8843C86.2178 35.9224 86.2954 35.954 86.3968 35.9794L86.6772 36.0459C86.8324 36.0839 86.9591 36.1441 87.0573 36.2265C87.1556 36.3057 87.2284 36.396 87.276 36.4974C87.3235 36.5956 87.3472 36.6954 87.3472 36.7968C87.3472 36.9394 87.306 37.0645 87.2237 37.1722C87.1445 37.2799 87.0352 37.3639 86.8958 37.4241C86.7564 37.4811 86.598 37.5096 86.4205 37.5096Z"
                                fill="black" />
                        </svg>
                        <!-- <img class="logo " :src="logo" alt="Logexa Logo"> -->
                    </router-link>
                </div>
                <div class="col-12 col-md-4 mx-auto mb-3 order-md-5 order-sm-2">
                    <form @submit.prevent="submit">
                        <h5 class="disc_header fw-500 text-xlarge">{{ $t("footer.disc_header") }}</h5>
                        <p class="disc_desc fw-400 text-base mb-0">{{ $t("footer.disc_desc") }}</p>
                        <p class="disc_desc fw-400 text-base">{{ $t("footer.disc_desc2") }}</p>
                        <div class="d-flex flex-column flex-sm-row w-100 gap-2 mb-3">

                            <label for="newsletter1" class="visually-hidden">{{ $t('common.email_address')
                                }}</label>
                            <div style="display: block;">
                                <input id="newsletter1" type="text" v-model="form.name"
                                    :placeholder="$t('yourName') + ' *'" class="form-control">
                                <div class="errors" v-if="BEErrors.errors && BEErrors.errors.name">
                                    <p class="error" v-for="error in BEErrors.errors.name" :key="error + 'beerrorf'">
                                        * {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div style="display: block;">
                                <input id="newsletter1" type="text" v-model="form.email"
                                    :placeholder="$t('footerEmailPlaceholder') + ' *' " class="form-control">
                                <div class="errors" v-if="BEErrors.errors && BEErrors.errors.email">
                                    <p class="error" v-for="error in BEErrors.errors.email" :key="error + 'beerrorf'">
                                        * {{ error }}
                                    </p>
                                </div>
                            </div>


                        </div>
                        <button class="btn btn-primary" :disabled="!form.name || !form.email" type="submit">{{
                            $t('common.subscribe') }}</button>
                        <div v-if="success" class="alert alert-success mt-3" role="alert">
                            {{ $t('subscribedSuccessfully') }}
                        </div>
                        <div class="follow-us">
                            <p class="mb-0">{{ $t('common.follow_us') }} : </p>
                            <SocialMediaLinks />
                        </div>
                        <!-- <ul class="list-unstyled d-flex">
                            <li class="ms-3"><router-link class="link-dark" aria-label="Read more about Seminole tax hike"
                                     :to="`/`"><svg class="bi" width="24" height="24">
                                        <use xlink:href="#twitter"></use>
                                    </svg></router-link></li>
                            <li class="ms-3"><router-link class="link-dark" aria-label="Read more about Seminole tax hike"
                                     :to="`/`"><svg class="bi" width="24" height="24">
                                        <use xlink:href="#instagram"></use>
                                    </svg></router-link></li>
                            <li class="ms-3"><router-link class="link-dark" aria-label="Read more about Seminole tax hike"
                                     :to="`/`"><svg class="bi" width="24" height="24">
                                        <use xlink:href="#facebook"></use>
                                    </svg></router-link></li>
                        </ul> -->
                    </form>
                </div>
                <div class="col-12 col-sm-4 col-md-2 mb-3 order-sm-2">
                    <h4 class="fw-500 mb-3">{{ $t('footer.General') }}</h4>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-3"><router-link :to="`/`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.Home')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/how-it-works`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.HowWeWork')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/about-us`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.AboutLogexa')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/blog`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.Blog')
                                }}</router-link></li>

                    </ul>
                </div>



                <div class="col-12 col-sm-4 col-md-2 mb-3 order-sm-3">
                    <h4 class="fw-500 mb-3">{{ $t('footer.ForYou') }}</h4>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-3"><router-link :to="`/pallet-storage`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer_v2.pallet_storage')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/sqm-storage`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer_v2.sqm_storage')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/transportation`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer_v2.transportation')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/services`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer_v2.service_provider')
                                }}</router-link></li>

                    </ul>
                </div>

                <div class="col-12 col-sm-4 col-md-2 mb-3 footer-border order-sm-4">
                    <h4 class="fw-500 mb-3">{{ $t('footer.Others') }}</h4>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-3"><router-link :to="`/contact-us`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.ContactUs')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/privacy-policy`"
                                class="nav-link p-0 text-small-16 text-muted">{{
                                $t('footer.PrivicyPolicy')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/`"
                                class="nav-link p-0 text-small-16 text-muted">{{
                                $t('footer.TermsAndConditions')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/careers`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.Careers')
                                }}</router-link></li>
                        <li class="nav-item mb-3"><router-link :to="`/faq`"
                                class="nav-link p-0 text-small-16  text-muted">{{
                                $t('footer.FAQ')
                                }}</router-link></li>
                    </ul>
                </div>


            </div>

            <!-- <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>© 2022 Company, Inc. All rights reserved.</p>

            </div> -->
        </footer>
    </div>
</template>

<script>
//import logo from '@/assets/images/LogexaLogo.svg';
// import { onMounted } from 'vue';

// onMounted(() => {
//     const preloadLink = document.createElement('link');
//     preloadLink.rel = 'preload';
//     preloadLink.as = 'image';
//     preloadLink.href = '@/assets/images/LogexaLogo.svg'; // Path to your image
//     document.head.appendChild(preloadLink);
// });
import { env } from '@/static/env'
import axios from 'axios';
import SocialMediaLinks from "@/components/global/SocialMediaLinks.vue";
export default {
    name: 'FooTer',
    components: {
        SocialMediaLinks
    },
    data() { 
        // return {
        //     logo: logo,
        // }
        return {
            env: env,
            BEErrors: {},
            success:false,
            form: {
                email: '',
                name: ''
            }
        }
    },
 
    // mounted() {
    //     const preloadLink = document.createElement('link');
    //     preloadLink.rel = 'preload';
    //     preloadLink.as = 'image';
    //     preloadLink.href = this.logo; // Use the logo from data
    //     document.head.appendChild(preloadLink);
    // }
    methods: {
        async submit() {
            try {
                this.success = false
                // this.preventSubmission()
                axios.post(`https://bin-test.logexa.com/api/newsletter/subscribe`, {
                    name: this.form.name,
                    email: this.form.email 
                })
                    .then(res => {
                        // this.allowSubmission()
                        this.BEErrors = { success: res.message }
                        this.success = true

                        // clear all inputs
                        this.form.name = ''
                        this.form.email = ''
                       
                    })
                    .catch(err => {
                        console.log(err);
                        this.success = false
                        //this.allowSubmission()
                        this.BEErrors = {}
                        if (err.response && err.response.data) {
                            this.BEErrors = Object.assign({}, { errors: err.response.data.errors }, { error: err.response.data.message }, { message: err.response.data.message })

                        }
                    })
            }
            catch (error) { console.log('Login error:', error) }
        },
    }
}
</script>


<style scoped lang="scss">
.flex-order-sm {
    display: flex;
    flex-wrap: wrap;
}
.footer-border {
    border-left: 2px solid $code-grey-2;
}
 .logo {
     width: 100%;
     /* or max-width: 100%; */
     height: auto;
     aspect-ratio: 200 / 100;
     max-width: 175px;
 }

 .disc_header {
      
     color: $primary-color;
 }

 @media screen and (max-width: $breakpoint-medium){
    .logo{
        max-width: 100px;
    }
 }
 .follow-us {
     display: flex;
     align-items: center;
     padding: 32px 0px 55px 0px;

     p {
         font-weight: 500;
         font-size: 16px;
         line-height: 19px;
         color: #5D5D5D;
         padding: 0px 16px 0px 0px;
     }
 }
  .follow-us {
      display: flex;
      align-items: center;
      padding: 32px 0px 55px 0px;

      p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #5D5D5D;
          padding: 0px 0px 0px 16px;
      }
  }

.error{
    color:red;
    font-size: 12px;
}
</style>
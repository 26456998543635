 
const routes = [
  
  {


    path: '/',
     
    beforeEnter: (to, from, next) => {
      // Determine the current language, possibly from `vue-i18n` or default
      const currentLang = getCurrentLanguage(); // Implement this function
      console.log('currentLang', currentLang);
      next(`/${currentLang}/`);
    },
    component: HomeView
  },
  
  {
    path: '/:lang/home',
    name: 'Home',
    component: HomeView
  },
  {


    path: '/:lang/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/:lang/about-us',
    name: 'About',
   
    component: () => import( '@/views/AboutView.vue')
  },
  {
    path: '/:lang/transportation',
    name: 'Transportation',
    
    component: () => import(  '@/views/Transportation.vue')
  },
  {  
    path: '/:lang/contact-us',
    name: 'Contact Us',

    component: () => import('@/views/contact-us.vue')
  },
  {
    path: '/:lang/how-it-works',
    name: 'how-it-works',

    component: () => import('@/views/how-it-works.vue')
  },
  {
    path: '/:lang/transportation',
    name: 'Transportation',

    component: () => import('@/views/Transportation.vue')
  },
  {
    path: '/:lang/sqm-storage',
    name: 'SQM Storage',

    component: () => import('@/views/sqm-storage.vue')
  },
  {
    path: '/:lang/pallet-storage',
    name: 'Pallet Storage',

    component: () => import('@/views/pallet-storage.vue')
  }, 
  {
    path: '/:lang/services',
    name: 'Services',

    component: () => import('@/views/ServicesPage.vue')
  },
  {
    path: '/:lang/blog',
    name: 'Blogs',

    component: () => import('@/views/blog/blogPage.vue')
  },
  {
    path: '/:lang/blog/:slug',
    name: 'Single Blog',

    component: () => import('@/views/blog/_slug.vue')
  },
  {
    path: '/:lang/news',
    name: 'News',

    component: () => import('@/views/news/newsPage.vue')
  },
  {
    path: '/:lang/news/:slug',
    name: 'Single news',

    component: () => import('@/views/news/_slug.vue')
  },

  {
    path: '/:lang/faq',
    name: 'FAQ',

    component: () => import('@/views/faq.vue')
  },
  {
    path: '/:lang/careers',
    name: 'Careers',

    component: () => import('@/views/CareersPage.vue')
  },
  {
    path: '/:lang/privacy-policy',
    name: 'Privacy Policy',

    component: () => import('@/views/privacy-policy.vue')
  },
  { 


    path: '/:lang',
    beforeEnter: (to, from, next) => {
     
      // Determine the current language, possibly from `vue-i18n` or default
      const currentLang = getCurrentLanguage(); // Implement this function
      console.log('currentLang', currentLang);
      next(`/${currentLang}/`);
    },
    component: HomeView
  },
]
 


import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { i18n } from '@/i18n';
 

function getCurrentLanguage() {
  // This function should return the current language code
  // For example, it could return this.$i18n.locale in a Vue component context
  // For simplicity, let's return 'en' as the default language here
  return 'ar';
}
 
function getMetaForRoute(routeName, lang = 'ar') {
  var seo = {
    title: "أفضل مستودع تخزين للايجار جدة – أفضل مستودع للايجار الشهري بالرياض",
    description: "إذا كنت تبحث عن مستودع صغير للايجار الشهري فقد وصلت! مع لوجيكسا لحلول تخزين المستودعات أصبح بإمكانك تخزين منتجاتك في الرياض وجدة بأمان مع إمكانية إدارة مخزنك عبر منصتنا ",
    keywords: ", خدمات لوجستية, الخدمات اللوجستية في السعودية, شركة لوجستية, خدمات اللوجستية, شركة للخدمات اللوجستية, ادارة المخزون, إدارة المستودعات, طرق التخزين في المستودعات, نظام التخزين في المستودعات, إدارة الخدمات اللوجستية, الشركات اللوجستية, القطاع اللوجستي, اللوجستية السعودية, شروط التخزين في المستودعات, قطاع الخدمات اللوجستية, الخدمات اللوجستية السعودية, مستودعات, تخزين, مستودع صغير للايجار, ايجار مستودع, تأجير مساحات تخزين, مستودعات للايجار, مستودعات تخزين, مشروع مستودعات تخزين, تخزين مستودع, الخدمات اللوجستية,  #لوجيكسا #تخزين #تخزين_ذكي #بضائع #تجارة_إلكترونية #السعودية #الرياض #جدة #الدمام ,مستودع, مستودع للايحار جدة, مستودع جدة, استئجار مستودع الرياض, مستودع صغير للايجار في الرياض ,مستودعات السلي مستودعات للايجار مستودعات الرياض مستودعات للايجار الرياض, مستودعات جدة, مستودعات الخمرة جدة ",

    main_page: {
      title: "أفضل مستودع تخزين للايجار جدة – أفضل مستودع للايجار الشهري بالرياض",
      description: "إذا كنت تبحث عن مستودع صغير للايجار الشهري فقد وصلت! مع لوجيكسا لحلول تخزين المستودعات أصبح بإمكانك تخزين منتجاتك في الرياض وجدة بأمان مع إمكانية إدارة مخزنك عبر منصتنا ",
      keywords: "",
    },
    about_us: {
      title: "من نحن لوجيكسا",
      description: "لوجيكسا هي أول منصة إلكترونية سعودية تسمح لك بتخزين بضائعك من خلال توفير خيارات تخرين مختلفة في عدة مواقع مع إمكانية إدارة المستودع عبر خدمة المستودع السحابي",
      keywords: "",
    },
    contact_us: {
      title: "تواصل معنا",
      description: "بإمكانك التواصل معنا بكل سهولة عبر منصتنا الإلكترونية، أو بالاتصال على الرقم الآتي: +966 544 311 384  أو بارسال بريد إلكتروني إلى عنواننا: info@logexa.com",
      keywords: "",
    },
    transportation_page: {
      title: "خدمات الشحن",
      description: "تجربة شحن سلسة وموثوقة مع خدمات الشحن الأفضل في المملكة العربية السعودية. نقدم حلولاً شاملة لنقل بضائعك بأمان وفعالية. اكتشف خياراتنا الآن!",
      keywords: "",
    },
    pallet_storage_page: {
      title: "التخزين بالطبلية ",
      description: "اكتشف حلول التخزين بالطبلية الفعالة والموفرة للمساحة مع لوجيكسا.  نوفر مساحات تخزينية مختلفة وحاويات متينة تناسب جميع الاحتياجات",
      keywords: "",
    },
    sqm_storage_page: {
      title: "التخزين بالمتر المربع ",
      description: "اكتشف حلول التخزين الفعالة بالمتر المربع مع لوجيكسا. نوفر المساحة الأكثر مناسبة لتلبية احتياجات منتجاتك في المملكة  العربية السعودية",
      keywords: "",
    },
    howitworks_page: {
      title: "كيف نعمل",
      description: "مع منصة لوجيكسا للحلول اللوجستية أصبح بإمكانك حجز مساحتك التخزينية بثلاث خطوات بسيطة. مع إمكانية إدارة المخزون عبر خدمة المستودع السحابي",
      keywords: "",
    },
    blog_page: {
      title: "مدونة لوجيكسا",
      description: "استكشف مدونة لوجيكسا للحصول على معلومات قيمة حول أحدث التطورات والاستراتيجيات في المجال اللوجستي. نصائح وأفكار مختلفة لإدارة المخزون بفعالية اقرأ المزيد الآن",
      keywords: "",
    },
    news_page: {
      title: "اخبار لوجيكسا",
      description: "ابقَ على اطّلاعٍ بأحدث أنشطة لوجكسا",
      keywords: "",
    },

  }
  if (lang == 'en') { 
    seo = {
      title: "Logexa",
      description: "With Logexa, the solution for warehouse storage in the Kingdom, you can now store your products securely through our network of strategically warehouses in Riyadh, Jeddah, and other cities. Enjoy the flexibility of monthly rental contracts and manage your warehouse with our cloud-based storage service. ",
      keywords: "chemical warehousing, logistics services, logexa, warehouse logistics, food warehousing, logexa, logistics saudi arabia, pallet storage  , ambient storage   , Warehouse, warehouse logistics, warehousing and logistics, warehousing space, chilled warehouse, business warehousing, ksa logistics, logistics and warehousing company, warehouse ksa, frozen warehousing, 3pl warehouse, warehouse rental, find warehouse, business warehouses, business warehouse storage, space warehouse, warehouse find, warehouse rental business, cheap warehouse for lease, rent a warehouse space near me, rental warehouse property management, warehouse rental spaces near me, lease of warehouse, space for warehouse, warehouse units near me, space warehouse for rent #Logexa #OnDemandWarehouse #warehousing #warehouse #logistics #SaudiArabia #jeddah #dammam #riyadh #logisticsservices #storage Warehouse in riyadh Riyadh Warehouse warehouse for rent in riyadh warehouse jeddah Khumra warehouse jeddah Pallet warehouse stoarge Cold storage warehouse management storage on-demand warehouse",

      main_page: {
        title: "Best Storage Solutions in Riyadh, Saudi Arabia ",
        description: "With Logexa, the solution for warehouse storage in the Kingdom, you can now store your products securely through our network of strategically warehouses in Riyadh, Jeddah, and other cities. Enjoy the flexibility of monthly rental contracts and manage your warehouse with our cloud-based storage service. ",
        keywords: "",
      },
      about_us: {
        title: "About Logexa",
        description: "Logexa is the first on-demand warehouse in Saudi Arabia. We offer storage solutions in several locations in Jeddah, Riyadh & Dammam. We offer pallet storage, SQM storage, and transportation services. Manage your inventory online through cloud warehouse.",
        keywords: "",
      },
      contact_us: {
        title: "Contact Us",
        description: "You can reach us any time through our platform or by email at info@logexa.com or by calling us at +966 544 311 384",
        keywords: "",
      },
      transportation_page: {
        title: "Transportation Services",
        description: "A smooth and reliable shipping experience with the best shipping services in the Kingdom of Saudi Arabia. We provide comprehensive solutions for transporting your goods safely and efficiently. Discover our options now!",
        keywords: "",
      },
      pallet_storage_page: {
        title: "Pallet Storage",
        description: "Discover efficient and space-saving pallet storage solutions with Logexa. We offer various storage spaces and sturdy containers that cater to all your needs.",
        keywords: "",
      },
      sqm_storage_page: {
        title: "SQM Storage",
        description: "Discover efficient square meter storage solutions with Logexa. We provide the most suitable space to meet the needs of your products in the Kingdom of Saudi Arabia.",
        keywords: "",
      },
      howitworks_page: {
        title: "How it works",
        description: "With Logexa logistics solutions platform, you can now reserve your storage space in three simple steps. Enjoy the convenience of managing your inventory through our cloud-based warehouse service.",
        keywords: "",
      },
      blog_page: {
        title: "Logexa Blog",
        description: "Explore Logexa's blog to find valuable information about the latest developments and strategies in the logistics field. Discover various tips and ideas for effectively managing inventory. Read more now.",
        keywords: "",
      },
      news_page: {
        title: "Logexa News",
        description: "Stay up to date with Logexa’s recent activites",
        keywords: "",
      },

    }
  }

  let siteMeta =[
    
  ]
  const metaTags = {
    'Home': {
      title: seo.main_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.main_page.description,
        },
        {
          property: 'og:description',
          content: seo.main_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },
    'About': {
      title: seo.about_us.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.about_us.description,
        },
        {
          property: 'og:description',
          content: seo.about_us.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },




    'Transportation': {
      title: seo.transportation_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.transportation_page.description,
        },
        {
          property: 'og:description',
          content: seo.transportation_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Contact Us': {
      title: seo.contact_us.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.contact_us.description,
        },
        {
          property: 'og:description',
          content: seo.contact_us.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'how-it-works': {
      title: seo.howitworks_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.howitworks_page.description,
        },
        {
          property: 'og:description',
          content: seo.howitworks_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'SQM Storage': {
      title: seo.sqm_storage_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.sqm_storage_page.description,
        },
        {
          property: 'og:description',
          content: seo.sqm_storage_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Pallet Storage': {
      title: seo.pallet_storage_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.pallet_storage_page.description,
        },
        {
          property: 'og:description',
          content: seo.pallet_storage_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Services': {
      title: seo.main_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.main_page.description,
        },
        {
          property: 'og:description',
          content: seo.main_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Blogs': {
      title: seo.blog_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.blog_page.description,
        },
        {
          property: 'og:description',
          content: seo.blog_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Single Blog': {
      title: seo.blog_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.blog_page.description,
        },
        {
          property: 'og:description',
          content: seo.blog_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'News': {
      title: seo.news_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.news_page.description,
        },
        {
          property: 'og:description',
          content: seo.news_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'Single news': {
      title: seo.news_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.news_page.description,
        },
        {
          property: 'og:description',
          content: seo.news_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },


    'FAQ': {
      title: seo.main_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.main_page.description,
        },
        {
          property: 'og:description',
          content: seo.main_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },



    'Careers': {
      title: seo.main_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.main_page.description,
        },
        {
          property: 'og:description',
          content: seo.main_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },



    'Privacy Policy': {
      title: seo.main_page.title,
      metaTags: [
        ...siteMeta,
        {
          name: 'description',
          content: seo.main_page.description,
        },
        {
          property: 'og:description',
          content: seo.main_page.description,
        },
        {
          property: 'keywords',
          content: seo.main_page.keywords,
        }
      ]
    },
 
    // Define meta tags for other routes...
  };

  return metaTags[routeName] || {};
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { left: 0, top: 0, behavior: 'auto' };
  },
})

// router.beforeEach((to, from, next) => {
//   const lang = to.path;
//   var language = 'ar'; // Default language

//   if (lang.includes('/en')) {
//     language = 'en';
//   } else if (lang.includes('/ar')) {
//     language = 'ar';
//   }

//   if (!lang.includes('/ar') && !lang.includes('/en')) {
//     // Redirect to default language if invalid
//     const defaultLang = 'ar'; // Default language
//     const path = `/${defaultLang}${to.path ? to.path : '/'}`;

//     // Include the ref query parameter if it exists
//     if (from.query.ref) {
//       //const query = to.fullPath.split('?')[1]; // Get the query parameters from the full path
//       return next({ path, query: { ref: from.query.ref } });
//     }

//     return next(path);
//   }

//   // Set document direction based on the language
//   if (language == 'ar') {
//     document.documentElement.setAttribute('dir', 'rtl');
//     i18n.global.locale = language;
//   } else {
//     document.documentElement.setAttribute('dir', 'ltr');
//     i18n.global.locale = language;
//   }
//   document.documentElement.setAttribute('lang', language);
//   // Include the ref query parameter if it exists in the from route
//   if (from.query.ref) {
//     to.query['ref'] = from.query.ref;
//   }
//   // Proceed with the navigation
//   // let query = to.query;
//   // let path = to.path;
//   // if (from.query.ref) {
//   //   //const query = to.fullPath.split('?')[1]; // Get the query parameters from the full path
//   //   query['ref'] = from.query.ref
//   // }
//   // next({ path, query });
//   next();
// });



router.beforeEach((to, from, next) => {
  const lang = to.path;
  let language = 'ar';  // Default language

  if (lang.includes('/en')) {
    language = 'en';
  } else if (lang.includes('/ar')) {
    language = 'ar';
  } else {
    // Retrieve language from session storage instead of localStorage
    language = sessionStorage.getItem('lang') === 'en' ? 'en' : 'ar';
  }
    // if (!lang.includes('/ar') && !lang.includes('/en')) {
    //   // Redirect to default language if invalid
    //   const defaultLang = 'ar'; // Default language
    //   const path = `/${defaultLang}${to.path ? to.path : '/'}`;

    //   // Include the ref query parameter if it exists
    //   if (from.query.ref) {
    //     //const query = to.fullPath.split('?')[1]; // Get the query parameters from the full path
    //     return next({ path, query: { ref: from.query.ref } });
    //   }

    //   return next(path);
    // }
  if (!lang.includes('/ar') && !lang.includes('/en')) {
    // Redirect to default language if invalid
    const defaultLang = sessionStorage.getItem('lang') || 'ar';
    const newPath = `/${defaultLang}${to.path ? to.path : '/'}`;
    // Include the ref query parameter if it exists
    console.log(from,to);
    if (from.query.ref || to.query.ref) {
      //const query = to.fullPath.split('?')[1];ß // Get the query parameters from the full path
      return next({ path: newPath, query: { ref: from.query.ref ? from.query.ref : to.query.ref } });
    }
    return next(newPath);
  }

  // Set document direction and language based on the language
  if (language === 'ar') {
    document.documentElement.setAttribute('dir', 'rtl');
  } else {
    document.documentElement.setAttribute('dir', 'ltr');
  }
  i18n.global.locale = language;
  document.documentElement.setAttribute('lang', language);
  if (from.query.ref) {
    to.query['ref'] = from.query.ref;
  }
  // Set language in session storage
  sessionStorage.setItem('lang', language);

  // Proceed with the navigation
  next();
});


// router.beforeEach((to, from, next) => {
//   const lang = to.path;
//   var language = 'ar'
//   //debugger;
//   if (lang.includes('/en') || lang.includes('/en')) {
//     language = lang.includes('/en') ? 'en' : 'ar';
//   } else {
//     language = localStorage.getItem('lang') == 'en' ? 'en' : 'ar';
//   }

//   if (!lang.includes('/ar') && !lang.includes('/en')) {
//     // Redirect to default language if invalid
//     const defaultLang = localStorage.getItem('lang') || 'ar';
//     const path = `/${defaultLang}${to.path ? to.path : '/'}`;

//     return next(path);
//   }

//   // Set document direction based on the language
//   if (language == 'ar') {
//     document.documentElement.setAttribute('dir', 'rtl');
//     i18n.global.locale = language

//   } else {
//     document.documentElement.setAttribute('dir', 'ltr');
//     i18n.global.locale = language
//   }
//   document.documentElement.setAttribute('lang', language);
//   localStorage.setItem('lang', language);
//   // Proceed with the navigation
//   next();
// });

 
router.beforeEach((to, from, next) => {
  if (!to) {
    return next();
  }
  const routeMeta = getMetaForRoute(to.name, i18n.global.locale);

  // Set document title
  document.title = routeMeta.title;

  // Remove previous meta tags
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

  // Set new meta tags
  if (routeMeta.metaTags) {
    routeMeta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(tag);
    });
  }

  next();
});



export default router

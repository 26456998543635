const ENV = "prod";

var BASE_URL;
var BASE_FRONT_URL;
switch(ENV) {
  case "local": BASE_URL = 'https://logexa-bin.test'; break;
  case "dev": BASE_URL = 'https://bin-test.logexa.com'; break;
  case "concept": BASE_URL = 'https://bin-concept-be.logexa.com'; break;
  case "prod": BASE_URL = 'https://bin-poc-be.logexa.com'; break;
  default: BASE_URL = 'https://bin-test.logexa.com'; break;
}

switch (ENV) {
  case "local": BASE_FRONT_URL = 'http://localhost:3000'; break;
  case "dev": BASE_FRONT_URL = 'https://lfed-test.logexa.com'; break;
  case "bin-test": BASE_FRONT_URL = 'https://bin-fe-test.logexa.com'; break;
  case "prod": BASE_FRONT_URL = 'https://dashboard.logexa.com'; break;
  case "bin-prod": BASE_FRONT_URL = 'https://bin-poc.logexa.com'; break;
  default: BASE_FRONT_URL = 'https://lfed-test.logexa.com'; break;
}

export const env = {
  NODE_ENV: ENV,
  // BASE_URL: ENV == 'dev' ? 'https://bin-test.logexa.com' : 'https://platform.logexa.com',
  BASE_URL,
  API_PATH: '/api',
  DASHBOARD_PATH: '/dashboard',
  BASE_FRONT_URL,
  RFQ_PATH: '/rfq',
}

<template>
  <div class="home">

    <HeroSection></HeroSection>
    <TrustedBy></TrustedBy>
    <OurServices></OurServices>
    <SaidAboutUs></SaidAboutUs>
    <WhyLogexa></WhyLogexa>
    <BeWithUs></BeWithUs>
    <TypesOfStorage></TypesOfStorage>
    <YourWhReady></YourWhReady>
    <ProductVariety></ProductVariety>
    <ContactUs></ContactUs>
    <FAQ></FAQ>
    <BlogSection></BlogSection>
    <SEOSection></SEOSection> </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection.vue'
import TrustedBy from '@/components/TrustedBy.vue'
import OurServices from '@/components/home/OurServices.vue'
import SaidAboutUs from '@/components/home/SaidAboutUs.vue'
import WhyLogexa from '@/components/home/WhyLogexa.vue'
import BeWithUs from '@/components/home/BeWithUs.vue'
import TypesOfStorage from '@/components/home/TypesOfStorage.vue'
import YourWhReady from '@/components/home/YourWhReady.vue'
import ProductVariety from '@/components/home/ProductVariety.vue'
import ContactUs from '@/components/home/ContactUs.vue'
import FAQ from "@/components/home/FAQ.vue"
import BlogSection from "@/components/home/BlogSection.vue"
import SEOSection from "@/components/home/SEOSection.vue"

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    SEOSection,
    TrustedBy,
    OurServices,
    SaidAboutUs,
    BeWithUs,
    WhyLogexa,
    TypesOfStorage,
    YourWhReady,
    ProductVariety,
    ContactUs,
    FAQ,
    BlogSection
  },
 
}
</script>


<style lang="scss" scoped>
.home{
  position: relative;
}
</style>